import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`15th June 2023`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Unix Domain Socket Support`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Unix_domain_socket"
          }}>{`Unix domain socket`}</a>{`
to serve and send requests which is useful for configuring a service mesh to communicate with a sidecar. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1837"
          }}>{`#1837`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4846"
          }}>{`#4846`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.common.util.DomainSocketAddress;

DomainSocketAddress domainSocketAddress = 
  DomainSocketAddress.of(Paths.get("/tmp/armeria.sock"));

Server
  .builder()
  .http(domainSocketAddress)
  ...
  .build();

WebClient
  .builder("http://" + domainSocketAddress.authority())
  ...
  .build(); 
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`WebSocket Support`}</strong>{`: You can now send and receive data over `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/WebSocket"
          }}>{`WebSocket`}</a>{`
using `}<a parentName="p" {...{
            "href": "type://WebSocketService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/websocket/WebSocketService.html"
          }}>{`type://WebSocketService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1076"
          }}>{`#1076`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3904"
          }}>{`#3904`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .service("/chat", WebSocketService.of((ctx, messages) -> {
      WebSocketWriter webSocketWriter = WebSocket.streaming();
      // Write frames using back pressure.
      return webSocketWriter;
   }));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Customizable Logging`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "type://LogFormatter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LogFormatter.html"
          }}>{`type://LogFormatter`}</a>{` and `}<a parentName="p" {...{
            "href": "type://LogWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LogWriter.html"
          }}>{`type://LogWriter`}</a>{` to customize the log
format and consumer. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3918"
          }}>{`#3918`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4267"
          }}>{`#4267`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4943"
          }}>{`#4943`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Convert RequestLog to JSON.
LogFormatter logFormatter = LogFormatter.ofJson();
LogWriter logWriter =
  LogWriter
    .builder() // Use SLF4J logger for logging.
    .logFormatter(logFormatter)
    .logger(logger)
    .build();

LoggingClient
  .builder()
  .logWriter(logWriter)
  .newDecorator();    
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`BlockHound Integrations`}</strong>{`: Armeria now provides BlockHound integrations, offering enhanced support for
detecting with blocking calls in event loops. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4493"
          }}>{`#4493`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Delayed Request Abortion`}</strong>{`: You can now delay aborting the `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` when the `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{`
completes. This may be useful when you want to send additional data even after the response is complete. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4913"
          }}>{`#4913`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .requestAutoAbortDelayMillis(2000) // Delay aborting the request for 2 second.
  ...
  .build();

WebClient
  .builder()
  .requestAutoAbortDelayMillis(1000) // Delay aborting the request for 1 second.
  ...
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Circuit Breaker and Retry Rule for timeout`}</strong>{`: You can now use `}<a parentName="p" {...{
            "href": "type://RetryRule#onTimeoutException():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html#onTimeoutException()"
          }}>{`type://RetryRule#onTimeoutException()`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://CircuitBreakerRule#onTimeoutException():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html#onTimeoutException()"
          }}>{`type://CircuitBreakerRule#onTimeoutException()`}</a>{` to open the circuit or retry the request when the response is
timed out. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4876"
          }}>{`#4876`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4882"
          }}>{`#4882`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Customizable ExecutionId for GraphQL-Java`}</strong>{`: You can now customize `}<inlineCode parentName="p">{`ExecutionId`}</inlineCode>{` of GraphQL-Java using
`}<a parentName="p" {...{
            "href": "type://GraphqlServiceBuilder#executionIdGenerator(ExecutionIdGenerator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlServiceBuilder.html#executionIdGenerator(com.linecorp.armeria.server.graphql.ExecutionIdGenerator)"
          }}>{`type://GraphqlServiceBuilder#executionIdGenerator(ExecutionIdGenerator)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4867"
          }}>{`#4867`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4877"
          }}>{`#4877`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GraphqlService
  .builder()
  .executionIdGenerator((requestCtx, query, operation, graphqlCtx) -> {
     return ExecutionId.from(requestContext.headers.get("traceparent"));
  })
  ...
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Custom CoroutineContext for gRPC-Kotlin`}</strong>{`: You can now inject a custom `}<inlineCode parentName="p">{`CoroutineContext`}</inlineCode>{` for gRPC-Kotlin
with `}<a parentName="p" {...{
            "href": "type://CoroutineContextProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/kotlin/CoroutineContextProvider.html"
          }}>{`type://CoroutineContextProvider`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4842"
          }}>{`#4842`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4866"
          }}>{`#4866`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`class CustomCoroutineContextProvider : CoroutineContextProvider { 
  override fun provide(ctx: ServiceRequestContext): CoroutineContext { 
    return ... // A custom CoroutineContext 
  }  
}
`}</code></pre>
        <Tip mdxType="Tip">
          <p parentName="li">{`Note that a custom `}<inlineCode parentName="p">{`CoroutineContextProvider`}</inlineCode>{` should be registered via Java SPI to
`}<inlineCode parentName="p">{`META-INF/services/com.linecorp.armeria.common.kotlin.CoroutineContextProvider`}</inlineCode>{`.`}</p>
        </Tip>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Retrieving CallOptions with GrpcCallOptions`}</strong>{`: With `}<a parentName="p" {...{
            "href": "type://GrpcCallOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcCallOptions.html"
          }}>{`type://GrpcCallOptions`}</a>{` in Armeria, you can now easily
retrieve `}<inlineCode parentName="p">{`CallOptions`}</inlineCode>{` from a given `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4776"
          }}>{`#4776`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4822"
          }}>{`#4822`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcClients
  .builder(grpcServerUri)
  .decorator((delegate, ctx, req) -> {
    CallOptions options = GrpcCallOptions.get(ctx);
    MyOption myOption = options.getOption(myOptionKey);
    // act on myOption if needed
    ...

    return delegate.execute(ctx, req);
  })
  .build(MyGrpcStub.class);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Fallback Strategy for CircuitBreakerClient`}</strong>{`: You can now easily set a fallback strategy to
`}<a parentName="p" {...{
            "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
          }}>{`type://CircuitBreakerClient`}</a>{` when a `}<a parentName="p" {...{
            "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
          }}>{`type://CircuitBreaker`}</a>{` is open. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4800"
          }}>{`#4800`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4818"
          }}>{`#4818`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`CircuitBreakerClient
  .builder(...)
  .recover((ctx, req) -> {
    // A fallback response when the circuit is open.
    return HttpResponse.of(...);
  });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Netty ChannelPipeline Customizer`}</strong>{`: You can now customize the Netty `}<inlineCode parentName="p">{`ChannelPipeline`}</inlineCode>{` for the server side `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4710"
          }}>{`#4710`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4813"
          }}>{`#4813`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .childChannelPipelineCustomizer(pipeline -> {
    // Add a custom handler to the pipeline.
  })
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HTTP/2 over TLS without ALPN`}</strong>{`: Armeria now supports using HTTP/2 over TLS without the need for
`}<a parentName="p" {...{
            "href": "https://datatracker.ietf.org/doc/html/draft-ietf-httpbis-http2-17#section-3.3"
          }}>{`ALPN`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4702"
          }}>{`#4702`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4704"
          }}>{`#4704`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory
  .builder()
  .useHttp2WithoutAlpn(true)
  ...
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Armeria with Jetty`}</strong>{`: Armeria is now compatible with Jetty 10 and Jetty 11. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4845"
          }}>{`#4845`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Armeria with Thrift`}</strong>{`: Armeria is now compatible with Thrift 0.18.1. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4812"
          }}>{`#4812`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContext.makeContextAware()`}</inlineCode>{` now returns instances of `}<a parentName="li" {...{
          "href": "type://ContextAwareRunnable:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareRunnable.html"
        }}>{`type://ContextAwareRunnable`}</a>{`,
`}<a parentName="li" {...{
          "href": "type://ContextAwareCallable:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareCallable.html"
        }}>{`type://ContextAwareCallable`}</a>{`, `}<a parentName="li" {...{
          "href": "type://ContextAwareFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareFunction.html"
        }}>{`type://ContextAwareFunction`}</a>{`, `}<a parentName="li" {...{
          "href": "type://ContextAwareBiFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareBiFunction.html"
        }}>{`type://ContextAwareBiFunction`}</a>{`,
`}<a parentName="li" {...{
          "href": "type://ContextAwareConsumer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareConsumer.html"
        }}>{`type://ContextAwareConsumer`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ContextAwareBiConsumer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareBiConsumer.html"
        }}>{`type://ContextAwareBiConsumer`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4878"
        }}>{`#4878`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4890"
        }}>{`#4890`}</a></li>
      <li parentName="ul">{`Annotated services now perform as fast as functional-style services under the following conditions: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4888"
        }}>{`#4888`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The annotated method returns an `}<inlineCode parentName="li">{`HttpResponse`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The annotated method is run on the event loop (i.e. not annotated with `}<inlineCode parentName="li">{`@Blocking`}</inlineCode>{`).`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You can now use the following features with Spring Boot Actuator integration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4874"
        }}>{`#4874`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4880"
        }}>{`#4880`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`management.endpoints.web.exposure.*`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`management.endpoints.web.path-mapping.*`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`management.endpoints.web.discovery.*`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`management.endpoint.health.status.http-mapping.*`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`application/vnd.spring-boot.actuator.v3+json`}</inlineCode>{`, `}<inlineCode parentName="li">{`application/vnd.spring-boot.actuator.v2+json`}</inlineCode>{` and
`}<inlineCode parentName="li">{`application/json`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Armeria client now exports metrics for `}<a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{` with the following metric name: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3350"
        }}>{`#3350`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4707"
        }}>{`#4707`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.actual.requests.attempts`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`You can now set the default log name for `}<a parentName="li" {...{
          "href": "type://VirtualHost:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHost.html"
        }}>{`type://VirtualHost`}</a>{` via
`}<a parentName="li" {...{
          "href": "type://VirtualHostBuilder#defaultLogName(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHostBuilder.html#defaultLogName(java.lang.String)"
        }}>{`type://VirtualHostBuilder#defaultLogName(String)`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4817"
        }}>{`#4817`}</a></li>
      <li parentName="ul">{`Synchronized blocks in `}<inlineCode parentName="li">{`core/common`}</inlineCode>{` have been migrated to `}<inlineCode parentName="li">{`ReentrantLock`}</inlineCode>{` for improved compatibility with
virtual threads. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4578"
        }}>{`#4578`}</a></li>
      <li parentName="ul">{`We did groundwork for supporting PKCS`}{`#`}{`12 and JKS keystore formats when configuring TLS in a future release. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4801"
        }}>{`#4801`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{` no longer decodes a percent-encoded `}<inlineCode parentName="li">{`?`}</inlineCode>{` (`}<inlineCode parentName="li">{`%3F`}</inlineCode>{`) in a request path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4907"
        }}>{`#4907`}</a></li>
      <li parentName="ul">{`Search domains are no longer used in DNS resolution when an authority ends with a trailing dot. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4538"
        }}>{`#4538`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4875"
        }}>{`#4875`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://FailFastException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/FailFastException.html"
        }}>{`type://FailFastException`}</a>{` is now correctly converted to `}<inlineCode parentName="li">{`Status.UNAVAILABLE`}</inlineCode>{` in gRPC clients. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4805"
        }}>{`#4805`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4924"
        }}>{`#4924`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`DocService`}</inlineCode>{` now correctly makes the link to `}<a parentName="li" {...{
          "href": "type://StructInfo:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/StructInfo.html"
        }}>{`type://StructInfo`}</a>{` even when a protobuf `}<inlineCode parentName="li">{`Message`}</inlineCode>{` is used in both gRPC
services and annotated services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4885"
        }}>{`#4885`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ConcurrencyLimitingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/limit/ConcurrencyLimitingClient.html"
        }}>{`type://ConcurrencyLimitingClient`}</a>{` used with `}<a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{` or `}<a parentName="li" {...{
          "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
        }}>{`type://CircuitBreakerClient`}</a>{` now
doesn't produce a deadlock. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4826"
        }}>{`#4826`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4834"
        }}>{`#4834`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`connection: close`}</inlineCode>{` is set correctly in the response when an HTTP/1.1 request contains `}<inlineCode parentName="li">{`connection: close`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4864"
        }}>{`#4864`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when using `}<a parentName="li" {...{
          "href": "type://StreamMessage#mapError(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#mapError(java.util.function.Function)"
        }}>{`type://StreamMessage#mapError(Function)`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4852"
        }}>{`#4852`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4859"
        }}>{`#4859`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` now correctly generates specifications for Thrift services compiled with `}<inlineCode parentName="li">{`java:fullcamel`}</inlineCode>{`
option. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4858"
        }}>{`#4858`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` with relative paths now correctly provides autocompletion. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4840"
        }}>{`#4840`}</a></li>
      <li parentName="ul">{`The unhandled exception reporter no longer reports ignorable exceptions. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4861"
        }}>{`#4861`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4922"
        }}>{`#4922`}</a></li>
      <li parentName="ul">{`Fixed an issue where `}<a parentName="li" {...{
          "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
        }}>{`type://@Param`}</a>{` annotation swallows exceptions raised from
`}<a parentName="li" {...{
          "href": "type://RequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverterFunction.html"
        }}>{`type://RequestConverterFunction`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4826"
        }}>{`#4826`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4834"
        }}>{`#4834`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`armeria-logback`}</inlineCode>{` is now compatible with Logback 1.3.x. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4855"
        }}>{`#4855`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4856"
        }}>{`#4856`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CoroutineServerInterceptor"
        }}>{`type://CoroutineServerInterceptor`}</a>{` now correctly propagates the coroutine context. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4889"
        }}>{`#4889`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4894"
        }}>{`#4894`}</a></li>
      <li parentName="ul">{`Armeria gRPC client now respects `}<inlineCode parentName="li">{`CallOptions.getExecutor()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4850"
        }}>{`#4850`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4851"
        }}>{`#4851`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
        }}>{`type://GraphqlService`}</a>{` now creates `}<inlineCode parentName="li">{`DataLoaderRegistry`}</inlineCode>{` for each request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4237"
        }}>{`#4237`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4455"
        }}>{`#4455`}</a></li>
      <li parentName="ul">{`Fixed a regression where "Copy as a cURL command" and "Copy response" in debug page did not work. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4929"
        }}>{`#4929`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
        }}>{`type://THttpService`}</a>{` no longer exposes field information in the response when failing to deserialize a request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4953"
        }}>{`#4953`}</a></li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`Added documentation for setting `}<a parentName="li" {...{
          "href": "/docs/server-timeouts"
        }}>{`request timeouts`}</a>{` at the server side. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4595"
        }}>{`#4595`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4860"
        }}>{`#4860`}</a></li>
      <li parentName="ul">{`Added documentation for `}<a parentName="li" {...{
          "href": "/docs/advanced-kotlin"
        }}>{`Kotlin integration`}</a>{` with Armeria. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4866"
        }}>{`#4866`}</a></li>
      <li parentName="ul">{`Mentioned `}<a parentName="li" {...{
          "href": "https://github.com/http4s/http4s-armeria"
        }}>{`http4s-armeria`}</a>{` as a part of the Scala integration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4919"
        }}>{`#4919`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4920"
        }}>{`#4920`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`The following APIs have been deprecated in favor of `}<a parentName="li" {...{
          "href": "type://LogWriterBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LogWriterBuilder.html"
        }}>{`type://LogWriterBuilder`}</a>{`:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://LoggingDecoratorBuilder#logger(Logger):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LoggingDecoratorBuilder.html#logger(org.slf4j.Logger)"
            }}>{`type://LoggingDecoratorBuilder#logger(Logger)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://LoggingDecoratorBuilder#requestLogLevelMapper(RequestLogLevelMapper):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LoggingDecoratorBuilder.html#requestLogLevelMapper(com.linecorp.armeria.common.logging.RequestLogLevelMapper)"
            }}>{`type://LoggingDecoratorBuilder#requestLogLevelMapper(RequestLogLevelMapper)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://LoggingDecoratorBuilder#responseLogLevelMapper(ResponseLogLevelMapper):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LoggingDecoratorBuilder.html#responseLogLevelMapper(com.linecorp.armeria.common.logging.ResponseLogLevelMapper)"
            }}>{`type://LoggingDecoratorBuilder#responseLogLevelMapper(ResponseLogLevelMapper)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://LoggingDecoratorBuilder#responseCauseFilter(Predicate):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/LoggingDecoratorBuilder.html#responseCauseFilter(java.util.function.Predicate)"
            }}>{`type://LoggingDecoratorBuilder#responseCauseFilter(Predicate)`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Changed the return type of `}<a parentName="li" {...{
          "href": "type://RequestContext#localAddress():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#localAddress()"
        }}>{`type://RequestContext#localAddress()`}</a>{`
and `}<a parentName="li" {...{
          "href": "type://RequestContext#remoteAddress():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#remoteAddress()"
        }}>{`type://RequestContext#remoteAddress()`}</a>{` from `}<inlineCode parentName="li">{`SocketAddress`}</inlineCode>{` to `}<inlineCode parentName="li">{`InetSocketAddress`}</inlineCode>{`
because `}<a parentName="li" {...{
          "href": "type://DomainSocketAddress:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/DomainSocketAddress.html"
        }}>{`type://DomainSocketAddress`}</a>{` is now an `}<inlineCode parentName="li">{`InetSocketAddress`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4846"
        }}>{`#4846`}</a></li>
      <li parentName="ul">{`The parameter type of the following methods has been changed from `}<inlineCode parentName="li">{`SocketAddress`}</inlineCode>{` to `}<inlineCode parentName="li">{`InetSocketAddress`}</inlineCode>{`: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4846"
        }}>{`#4846`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContextBuilder#localAddress(InetSocketAddress):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContextBuilder.html#localAddress(java.net.InetSocketAddress)"
            }}>{`type://ClientRequestContextBuilder#localAddress(InetSocketAddress)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContextBuilder#remoteAddress(InetSocketAddress):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContextBuilder.html#remoteAddress(java.net.InetSocketAddress)"
            }}>{`type://ClientRequestContextBuilder#remoteAddress(InetSocketAddress)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ServiceRequestContextBuilder#localAddress(InetSocketAddress):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContextBuilder.html#localAddress(java.net.InetSocketAddress)"
            }}>{`type://ServiceRequestContextBuilder#localAddress(InetSocketAddress)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContextBuilder#remoteAddress(InetSocketAddress):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContextBuilder.html#remoteAddress(java.net.InetSocketAddress)"
            }}>{`type://ClientRequestContextBuilder#remoteAddress(InetSocketAddress)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`An innermost `}<a parentName="li" {...{
          "href": "type://MetricCollectingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/MetricCollectingService.html"
        }}>{`type://MetricCollectingService`}</a>{` is now chosen to measure metrics for a service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4908"
        }}>{`#4908`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Previously, the outermost one was used to collect metrics.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.15.0 → 5.16.0`}</li>
      <li parentName="ul">{`Brotli4J 1.11.0 → 1.12.0`}</li>
      <li parentName="ul">{`Curator 5.4.0 → 5.5.0`}</li>
      <li parentName="ul">{`Dropwizard 2.1.5 → 2.1.6`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.18 → 4.2.19`}</li>
      <li parentName="ul">{`GraphQL Java 20.0 → 20.4`}</li>
      <li parentName="ul">{`GraphQL Kotlin 6.4.0 → 6.5.2`}</li>
      <li parentName="ul">{`gRPC-Java 1.49.1 → 1.56.0`}</li>
      <li parentName="ul">{`Jackson 2.14.2 → 2.15.2`}</li>
      <li parentName="ul">{`java-jwt 4.3.0 → 4.4.0`}</li>
      <li parentName="ul">{`Jetty 9.4.50.v20221201 -> 9.4.51.v20230217, 10.0.15, 11.0.0`}</li>
      <li parentName="ul">{`Kafka client 3.4.0 → 3.4.1`}</li>
      <li parentName="ul">{`Kotlin 1.8.10 → 1.8.22`}</li>
      <li parentName="ul">{`Kotlin Coroutine 1.6.4 → 1.7.1`}</li>
      <li parentName="ul">{`Micrometer 1.10.5 → 1.11.1`}</li>
      <li parentName="ul">{`Netty 4.1.91 → 4.1.93`}
        <ul parentName="li">
          <li parentName="ul">{`io_uring 0.0.19 → 0.0.21`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Protobuf 3.21.1 -> 3.22.3`}</li>
      <li parentName="ul">{`protobuf-jackson 2.0.0 → 2.2.0`}</li>
      <li parentName="ul">{`Reactive gRPC 1.2.3 → 1.2.4`}</li>
      <li parentName="ul">{`Reactor 3.5.1 → 3.5.7`}</li>
      <li parentName="ul">{`Sangria 3.5.3 → 4.0.0`}</li>
      <li parentName="ul">{`Sangria slowlog 2.0.5 → 3.0.0`}</li>
      <li parentName="ul">{`Scala 2.12.17 → 2.12.18, 2.13.10 → 2.13.11, 3.2.2 → 3.3.0`}</li>
      <li parentName="ul">{`scala-collection-compat 2.9.0 → 2.10.0`}</li>
      <li parentName="ul">{`Spring 6.0.6 → 6.0.9`}</li>
      <li parentName="ul">{`Spring Boot 2.7.10 → 2.7.12, 3.0.5 → 3.1.0`}</li>
      <li parentName="ul">{`Tomcat 10.1.4 → 10.1.10`}</li>
      <li parentName="ul">{`Thrift 0.18.1`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['0lejk4', 'AngerM-DD', 'Bue-von-hon', 'KarboniteKream', 'Lincong', 'amirhadadi', 'anuraaga', 'arinchampatidd', 'be-hase', 'danicheg', 'echo304', 'heowc', 'himangi-db', 'ikhoon', 'injae-kim', 'jrhee17', 'kojilin', 'mauhiz', 'minwoox', 'my4-dev', 'nhacvuong', 'rhodo', 'richieyan', 'seonwoo960000', 'staktrace', 'ta7uw', 'tomatophobia', 'trustin', 'vkostyukov', 'wreulicke']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      